import Link from "next/link";
import { Session } from "next-auth";

import styles from "./NavBar.module.css";

type NavBarProps = {
  session: Session;
};

export default function NavBar({ session }: NavBarProps) {
  if (!session) {
    return null;
  }

  return (
    <nav className={`navbar navbar-dark ${styles.navbar}`}>
      <div className="container">
        <div>
          <Link href="/">
            <a className="navbar-brand">
              <img src="/logo.png" className={`me-2 ${styles.logo}`} />
              Spotlight
            </a>
          </Link>
        </div>
        <div>
          <ul className="navbar-nav">
            <li className="nav-item">
              <span className="nav-link active">{session?.user.name}</span>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
