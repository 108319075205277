import { useState, useEffect } from "react";
import Head from "next/head";
import { Session } from "next-auth";

import NavBar from "components/NavBar";

type LayoutProps = {
  children?: React.ReactNode;
  session: Session;
};

export default function Layout({ children, session }: LayoutProps) {
  const [isDev, setIsDev] = useState(false);

  useEffect(() => {
    const host = window.location.host;
    setIsDev(host.includes("dev.tda.link"));
  });

  return (
    <div>
      <Head>
        <title>Spotlight</title>
      </Head>
      {session && isDev && (
        <div className="alert alert-info w-100 mb-0 text-center pt-1 pb-1 text-uppercase border-0 rounded-0">
          Development Environment
        </div>
      )}
      <NavBar session={session} />
      <main className="container mt-3">{children}</main>
    </div>
  );
}
